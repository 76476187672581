import { signal } from '@angular/core';
import { BaseStore } from '@lib-core/stores';
import { get, set } from '@lib-core/helpers';

export class BaseListStore<T> extends BaseStore<T> {
	private cookieListName: string;

	private currentList = signal<T[]>(null);

	constructor(cookieName: string, cookieListName: string) {
		super(cookieName);
		this.cookieListName = cookieListName;
	}

	removeNullValues(data: T[]) {
		return data?.length ? data.filter(m => m) : [];
	}

	setList(data: T[], setFirstItem = true) {
		set(this.cookieListName, data);

		this.currentList.update(() => {
			return [...this.removeNullValues(data)];
		});

		if (setFirstItem && data?.length > 0) {
			this.set(data[0]);
		}
	}

	setByProp(value: string, propName = 'code') {
		const list = this.getList();

		if (list && list.length > 0) {
			const item = list.find((m: any) => m[propName] === value);
			if (item) {
				this.set(item);
			}
		}
	}

	getList(): T[] {
		let data = this.currentList();

		if (!data) {
			data = get(this.cookieListName);
			if (data) {
				this.currentList.update(() => {
					return data;
				});
			}
		}

		return data;
	}

	clearList() {
		this.clear();
		this.setList(null);
	}

	count() {
		return this.getList()?.length;
	}

	isEmpty() {
		return this.count() == 0;
	}
}
